@use '../base/colors' as couleur;

.pibi {
  border: 1px solid rgb(220, 53, 69) !important;
  color: rgb(220, 53, 69);
  background-color: rgba(220, 53, 69, 0.1) !important;
}

.pena {
  border: 1px solid rgb(23, 162, 184) !important;
  color: rgb(23, 162, 184);
  background-color: rgba(23, 162, 184, 0.1) !important;
}

// Mise en forme des pages
#body {
  height: 100%;
  display: flex;
  flex-direction: column;

  #footer {
    margin-top: auto;
    margin-bottom: -32px;
    padding-bottom: 32px;
  }
}

//conteneur avec une auteur fixé qui contiendra des enfants en ligne (direction: column)
//dont un enfant scrollable (où un autre container-fixe si le scrollable est un sous enfant)
.container-hfixed {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-full {
    flex-grow: 1;
  }

  .content-scroll {
    flex-grow: 1;
  }
}

//conteneur qui contiendra des enfants en colonne (direction: row) dont un enfant scrollable
.container-hfixed-row {
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  height: 100%;

  &.row {
    flex-wrap: nowrap;
  }

  &.auto-grow > * {
    flex-grow: 1;
  }
}

.content-scroll {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(208, 208, 208) rgb(245, 245, 245);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(245, 245, 245);
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(208, 208, 208);
    border-radius: 5px;
  }
}

.frosted-glass {
  border-radius: 8px;
  border: 1.6px solid white !important;
  background: couleur.$bg-frosted-glass;
  box-shadow: 0px 0.88px 7.2px 2.8px rgba(94, 94, 94, 0.08);
  //backdrop-filter: blur(14.4px);

  &.active {
    border-color: couleur.$primary;
    background-color: rgb(78, 185, 168, 0.96);
  }

  .header {
    border-radius: 8px;
    outline: 1.6px solid white;

    &.active {
      outline-color: couleur.$primary;
      background-color: rgb(78, 185, 168, 0.04);
    }
  }
  &.link:hover {
    border-color: rgba($color: couleur.$primary, $alpha: 0.32);
    text-decoration: none;
  }
  &.card-link {
    cursor: pointer;
    &:hover {
      transition: all 0.3s;
      background-color: rgba($color: couleur.$bg-frosted-glass, $alpha: 0.3);
    }
  }
}

.fg-shadow {
  box-shadow: 0px 0.88px 7.2px 2.8px rgba(94, 94, 94, 0.08);
}
.fg-background {
  background-color: couleur.$bg-frosted-glass !important;
  backdrop-filter: blur(14.4px);
}
.fg-border {
  border-radius: 8px;
  border: 1.6px solid white !important;
}

input:not([class^='bg-'], [type='radio'], [type='checkbox']),
select:not([class^='bg-']),
textarea:not([class^='bg-']) {
  background-color: couleur.$bg-frosted-glass !important; //couleur.$bg-frosted-glass
  backdrop-filter: couleur.$blur-frosted-glass;
}

.pastille {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;

  &.green {
    background-color: #28a745;
  }

  &.red {
    background-color: #dc3545;
  }
}

//Bootstrap
.badge {
  line-height: normal !important;
  font-weight: 400 !important;
  white-space: normal !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

//Openlayers
.ol-popup-container {
  position: absolute;
  background-color: white;

  padding: 8px;
  border: 1px solid couleur.$supersilver;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  bottom: 12px;
  left: -50px;
  min-width: 200px;
  &::after,
  &::before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  &::before {
    border-top-color: couleur.$supersilver;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.ol-popup-closer:after {
  content: '✖';
}

//Full Calendar
.fc-daygrid-block-event {
  padding-left: 4px;
}
.inherit {
  all: inherit;
}
.inherit-color {
  color: inherit;
}

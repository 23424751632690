.text-blue {
  color: #007bff;
}

a {
  text-decoration: none !important;
}

.link {
  &:hover {
    text-decoration: underline;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// Nuancier Metarisc (https://zeroheight.com/77760495c/p/62c619-couleurs/t/34687c)
$lollipop: #d01f3c;
$darkest: #5e0f1b;
$darker: #9e192d;
$dark: #c41f38;
$lighter: #df233e;
$lightest: #eb2643;

// Couleurs principales Metarisc (https://zeroheight.com/77760495c/p/62c619-couleurs/t/317102)
$supersilver: #f0f0f0; // Use this color or the one below
$smokedpearl: #656466;
$vulcan: #36393d;
$white: #ffffff;

$compare_color: rgb(240, 157, 0);

$primary: rgb(78, 185, 168);
$secondary: rgb(108, 117, 125);
$success: rgb(40, 167, 69);
$warning: rgb(255, 193, 7);
$danger: rgb(220, 53, 69);
$info: rgb(23, 162, 184);
$light: $supersilver;

//Anomalie box
$buttermilk: #ffeeba;
$darkGoldenrod: #856404;

$bg-frosted-glass: rgba(255, 255, 255, 0.54);
$blur-frosted-glass: blur(14.4px);

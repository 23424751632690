.nav {
  .item-nav {
    margin-right: 2rem;
    padding-bottom: 0.25rem;

    a {
      color: black !important;
    }
    .active {
      font-weight: bold;
      border-bottom: 2px solid black;
    }
  }
}

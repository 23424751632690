@font-face {
  font-family: 'firasans-regular';
  src: url(../../fonts/FiraSans-Regular.ttf);
}
$firasans-regular:
  'firasans-regular',
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

@font-face {
  font-family: 'firasans-bold';
  src: url(../../fonts/FiraSans-Bold.ttf);
}
$firasans-bold:
  'firasans-bold',
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

@font-face {
  font-family: 'firasans-medium';
  src: url(../../fonts/FiraSans-Medium.ttf);
}
$firasans-medium:
  'firasans-medium',
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

@font-face {
  font-family: 'firasans-semi-bold';
  src: url(../../fonts/FiraSans-SemiBold.ttf);
}
$firasans-semi-bold:
  'firasans-semi-bold',
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

@font-face {
  font-family: 'firasans-light';
  src: url(../../fonts/FiraSans-Light.ttf);
}
$firasans-light:
  'firasans-light',
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

@font-face {
  font-family: 'firasans-light-italic';
  src: url(../../fonts/FiraSans-LightItalic.ttf);
}
$firasans-light-italic:
  'firasans-light-italic',
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

.ff-regular {
  font-family: $firasans-regular;
}

.ff-bold {
  font-family: $firasans-bold;
}

.ff-medium {
  font-family: $firasans-medium;
}

.ff-semi-bold {
  font-family: $firasans-semi-bold;
}

.ff-light {
  font-family: $firasans-light;
}
.ff-light-italic {
  font-family: $firasans-light-italic;
}

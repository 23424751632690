:root {
  --fc-button-bg-color: #656466;
  --fc-button-border-color: #656466;
  --fc-button-hover-bg-color: #36393d;
  --fc-button-hover-border-color: #36393d;
  --fc-button-active-bg-color: #36393d;
  --fc-button-active-border-color: #36393d;

  --fc-today-bg-color: rgba(78, 185, 168, 0.16);
}

@use '../base/colors' as couleur;

.btn-primary {
  color: couleur.$white !important;
}

.btn-outline-primary:hover {
  color: couleur.$white !important;
}

.btn-success {
  color: couleur.$white !important;
}

.btn-info {
  color: couleur.$white !important;
}

.btn-outline-info:hover {
  color: couleur.$white !important;
}

i.metadome {
  content: url('../../images/metadome.svg');
  width: 32px;
}
i.metadome-noir {
  content: url('../../images/metadome_noir.svg');
  width: 32px;
}

.bi-bold {
  -webkit-text-stroke: 1px;
}

i.mobilcos {
  content: url('https://www.mobilcos.fr/img/mobilcos.png');
}

i.platau {
  content: url('../../images/appli/platau.png');
}

i.prevarisc {
  content: url('../../images/appli/prevarisc.png');
}

i.erp {
  content: url('../../images/sidebar/erp.svg');
}

i.deci {
  content: url('../../images/sidebar/deci.svg');
}
